


















































import { Vue, Component, Prop, Watch } from 'vue-class-decorator'
import { GlobalModule } from '@/store'
import { Coordinates } from 'vue/types'

const DG = require('2gis-maps')

@Component({})
export default class GeocoderComponent extends Vue {
  @Prop({ default: '' }) readonly title!: string
  @Prop({
    default: () => {
      return {
        lat: 0,
        lon: 0,
        adress: ''
      }
    },
    required: true
  }) value!: Coordinates

  public readonly h2: string
  public adress: string
  public map: any
  public marker: any
  public cords: Coordinates
  public searchAdress: boolean
  public dadataAddresses: any
  public search: any

  constructor() {
    super()
    this.h2 = this.title
    this.map = {}
    this.marker = {}

    this.adress = this.value.adress
    this.cords = this.value

    this.searchAdress = false
    this.dadataAddresses = []
    this.search = null
  }

  // Store init
  @GlobalModule.Action('getCordByAdress') getCordByAdress!: (adress: string) => Promise<any>
  @GlobalModule.Action('getAdressByCords') getAdressByCords!: (cords: {lat: number; lon: number}) => Promise<any>
  @GlobalModule.Action('getAddressesList') getAddressesList!: (address: string) => Promise<any>
  @GlobalModule.Getter('getLoading') loading!: boolean

  @Watch('search') getAddresses(text) {
    this.getAddressesList(text)
      .then((res: any) => {
        this.dadataAddresses = res
      })
      .catch((error: any) => {
        this.$noty('error', error.message, 7000).show()
      })
  }

  @Watch('adress') changeAddress() {
    this.getCords()
  }

  @Watch('value') keyActivator() {
    if (this.adress.trim() === '' && this.value.adress.trim() !== '') {
      this.adress = this.value.adress
    }

    if (this.value.lat !== 0 && this.value.lon !== 0) {
      this.cords = this.value
      this.createMarkerOnMap(this.cords.lat, this.cords.lon)
    }
  }

  get viewCords() {
    return `${this.cords.lat}, ${this.cords.lon}`
  }

  render2GisMap() {
    this.map = DG.map('2gis-map', {
      center: [55.76125, 37.608074],
      zoom: 13
    })
    this.setStartCords()
  }

  getCords() {
    if (this.adress.trim() === '') {
      this.$noty('error', 'Введите адрес', 7000).show()
      return false
    }

    this.getCordByAdress(this.adress)
      .then((res: any) => {
        this.cords = res
        this.clearMarkerFromMap()
        this.createMarkerOnMap(this.cords.lat, this.cords.lon)
        this.handleInput()
      })
      .catch((error: any) => {
        this.$noty('error', error.message, 7000).show()
      })
  }

  async createMarkerOnMap(lat: number, lon: number) {
    this.marker = DG.featureGroup()
    DG.marker([lat, lon]).addTo(this.marker)

    this.marker.addTo(this.map)
    this.map.fitBounds(this.marker.getBounds())

    if (this.adress.trim() === '') {
      this.searchAdress = true
      this.adress = 'Идет определение...'

      await this.getAdressByCords({ lat, lon })
        .then((res: any) => {
          this.adress = res.value
          this.handleInput()
        })
        .catch((error: any) => {
          this.adress = ''
          this.$noty('error', error.message, 7000).show()
        })

      this.searchAdress = false
    }
  }

  clearMarkerFromMap() {
    if (Object.keys(this.marker).length !== 0) {
      this.marker.removeFrom(this.map)
    }
  }

  handleInput() {
    this.$emit('input', {
      adress: this.adress,
      lat: this.cords.lat,
      lon: this.cords.lon
    })
  }

  setStartCords() {
    if (this.cords.lat !== 0 && this.cords.lon !== 0) {
      this.createMarkerOnMap(this.cords.lat, this.cords.lon)
    }
    this.handleInput()
  }

  mounted() {
    this.render2GisMap()
  }
}
